import { Grid, Typography } from "@mui/material";
import React from "react";
import { TestQuestion } from "../forms/Assessments";
import { FormLine } from "../forms/FormLine";
import { FormLineRender } from "../forms/FormLineRender";
import { FormObject } from "../forms/FormObject";
import { FormRequest } from "../forms/FormRequest";
import { cloneObject, evalAfterLoadScript, evalBeforeSaveScript, evalDisableRules, getFormElement, getFormLineByColumnName, isNullOrUndefined, saveFormObject, setFormLineValue, setPageVariables } from "./FormScripts";
import WrappedButton from "./WrappedButton";

interface RenderProps {
	req?: FormRequest;
	form?: FormObject;
	isSubForm: boolean;
	suppressButtons?: boolean;
	suppressHeader?: boolean;
	afterLoadFunction?: Function;
	afterSaveFunction?: Function;
	beforeCloseFunction?: Function;
}
//        https://localhost:44429/landing?event_id=92ffb4dc-8ffb-41e1-ac6b-9d3237a59f1f&parent_value=7890f64d-5ad0-4b3d-baba-ed37a1d28b24&form_header_id=244ab7ba-c4da-4558-9aad-cdee82078792&key_value=new&form_mode=ADD&sitePS=undefined&programPS=3a09880a-2bb4-4118-83ed-8e3f665403b9&serviceTrack=f50b3c53-1781-4f1b-963a-46015775da75&programEnrollment=69ea2d02-394c-49cc-a6be-af17b45ad1a6&unitsId=&completeSchedEvent=false
export default function FormRender(props: RenderProps) {
	const [form, setForm] = React.useState(null as any as FormObject);
	const [loadSubreports, setLoadSubreports] = React.useState(false);
	const [saving, setSaving] = React.useState(false);
	const [evalAfterLoad, setEvalAfterLoad] = React.useState(false);

	// eslint-disable-next-line
	React.useEffect(() => {
		if (!props.form)
			fetch("form/GetFormObject", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				cache: "no-cache",
				body: JSON.stringify(props.req),
			})
				.then((x) => x.json())
				.then((x) => {
					const fo = new FormObject(x);
					fo.isSubForm = props.isSubForm;
					(window as any).currentForm = fo;
					(window as any).parentForm = fo;
					fo.FormLines = fo.FormLines.map((y: any) => new FormLine(y));
					if (fo.parentValue && fo.parentColumn) {
						fo.FormLines.find((x) => x.columnName?.toLowerCase() === fo.parentColumn.toLowerCase())?.setValue(fo.parentValue);
					}
					setPageVariables(fo);
					setForm(fo);
					setLoadSubreports(true);
				});
		else {
			props.form.isSubForm = props.isSubForm;
			if (!props.form.FormLines[0].setValue) {
				props.form.FormLines = props.form.FormLines.map((x: any) => new FormLine(x));
			}
			(window as any).currentForm = props.form;
			setPageVariables(props.form);
			setForm(props.form as any as FormObject);
			setLoadSubreports(true);
		}
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if (loadSubreports) {
			form.FormLines.filter((x: FormLine) => x.typeCode === "RSF" && x.isVisible).forEach((x: FormLine) => {
				fetch("form/GetSubreportData", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					cache: "no-cache",
					body: JSON.stringify({ form: form, subreport: x.srValue }),
				})
					.then((x) => x.json())
					.then((res) => {
						if (x.forceSetValue) {
							x.forceSetValue(JSON.parse(res).Item2);
						}
					});
			});
			props.afterLoadFunction && props.afterLoadFunction();
		}

		evalDisableRules();
		if (!evalAfterLoad) {
			evalAfterLoadScript();
			if (form) setEvalAfterLoad(true);
		}
		// eslint-disable-next-line
	}, [loadSubreports, form]);

	const handleSingleProgressNote = (form: any) => {
		const singleNote = "var_single_progess_note";
		if (getFormLineByColumnName(singleNote)) {
			var noteText = getFormElement(singleNote);
			const pnSf = form.FormLines.find((x: any) => x.subFormHeaderId && x.subFormHeaderId.toUpperCase() === "E4979E08-DBD6-493F-99E8-3092B193FD06");
			const pnSfValue = pnSf.emptyForm;

			pnSfValue.parentTable = "event_log";

			if (isNullOrUndefined(pnSfValue.keyValue) || pnSfValue.keyValue === "new" || pnSfValue.keyValue === pnSfValue.parentValue) {
				pnSfValue.keyValue = "new";
				pnSfValue.formMode = "ADD";
				pnSfValue.status = "ADD";
			} else if (isNullOrUndefined(noteText.replace("&nbsp;", ""))) {
				pnSfValue.status = "DELETE";
				pnSfValue.formMode = "DELETE";
			} else {
				pnSfValue.formMode = form.formMode;
				pnSfValue.status = form.formMode;
			}

			if (setFormLineValue("note_text", noteText, pnSfValue)) {
				// Flag as main note
				setFormLineValue("is_main_note", true, pnSfValue);
				// If there is no author, add current user
				if (isNullOrUndefined(getFormElement("progress_note_author", pnSfValue))) {
					setFormLineValue("progress_note_author", (window as any).workerID, pnSfValue);
				}

				pnSf.sfValue.push(pnSfValue);

				// Flag the form line where the progress note sub-form is to dirty
				pnSf.isDirty = true;
				pnSf.subFormHasData = true;
			}
		}
	};

	const getTestSave = (form: any) => {
		const f = (window as any).currentForm as FormObject;
		form.TestData = f.FormLines.filter((x) => x.typeCode === "TESTS" && x.isVisible).map((t) => {
			const assess = {
				test_setup_header_id: t.testValue.test_setup_header_id,
				Questions: [] as Array<any>,
				KeyValue: f.formMode === "ADD" ? "NEW" : f.keyValue,
				FormLinesID: t.formLinesId,
			};
			assess.Questions = t.testValue.Questions.filter((q: TestQuestion) => !q.is_group && !isNullOrUndefined(q.GetValue())).map((q: TestQuestion) => {
				var saveQ = {
					test_details_id: q.test_details_id || "new",
					test_header_id: assess.KeyValue,
					test_details_remarks: "",
					test_details_is_na: false,
					test_details_na_reason_id: "",
					questionCaption: q.caption,
					test_setup_details_id: q.test_setup_details_id,
					Answers: [] as Array<any>,
				};

				const selected = q.GetValue();
				switch (q.details_type_code) {
					case "SINGLELIST":
					case "NARRATIVE":
					case "NUMERIC_SCORE":
						if (selected) {
							const x = q.Answers.find((x) => x.test_setup_details_answers_id === selected || ["NARRATIVE", "NUMERIC_SCORE"].includes(q.details_type_code));
							saveQ.Answers.push({
								test_setup_details_answers_id: x?.test_setup_details_answers_id,
								test_details_answers_id: x?.test_details_answers_id || "NEW",
								test_details_id: x?.test_details_id || "NEW",
								question_type_code: q.details_type_code,
								value: q.details_type_code === "SINGLELIST" ? true : selected,
							});
						}
						break;
					case "MULTILIST":
						saveQ.Answers = Object.keys(selected).map((val) => {
							return {
								test_setup_details_answers_id: val,
								test_details_answers: "NEW",
								question_type_code: q.details_type_code,
								value: true,
							};
						});
						break;
				}

				return saveQ;
			});

			return assess;
		});
	};

	const prepSave = (form: any) => {
		form.FormLines.filter((x: any) => x.typeCode === "SF" || x.typeCode === "ESF").forEach((x: any) => {
			if (x.sfValue) {
				x.sfValue = x.sfValue.filter((y: any) => y.formMode === "ADD");
			}
		});
	};

	const save = () => {
		if (!validateBeforeSave()) return;
		(window as any).formValid = true;
		evalBeforeSaveScript();
		if (!(window as any).formValid) return;

		const formSave = cloneObject(form);
		getTestSave(formSave);
		formSave.isDstSame = true;
		prepSave(formSave);
		handleSingleProgressNote(formSave);

		formSave.FormLines = formSave.FormLines.filter((x: any) => x.isDirty);

		setSaving(true);
		saveFormObject(formSave)
			.then((x) => x.json())
			.then((x) => {
				if (!x.isError) {
					props.afterSaveFunction && props.afterSaveFunction();
					exitForm(true);
				} else {
					alert("There were validations encountered:\n" + x.messages.join("\n"));
				}

				setSaving(false);
			});
	};

	const exitForm = (suppress: boolean) => {
		if (!suppress && !window.confirm("Any unsaved changes will be lost, continue?")) return;

		props.beforeCloseFunction && props.beforeCloseFunction();

		window.close();
	};

	const validateBeforeSave = () => {
		const validations = [] as Array<string>;
		form.FormLines.filter((x) => x.isRequired && x.isModifiable && !x.getDisabled() && x.isVisible && ((x.typeCode !== "SF" && x.typeCode !== "ESF" && isNullOrUndefined(x.getValue())) || ((x.typeCode === "SF" || x.typeCode === "ESF") && x.getValue().length === 0))).forEach((x) => validations.push("- " + x.caption));

		const sf = form.FormLines.filter((x) => x.isVisible && !x.getDisabled() && x.isModifiable && (x.typeCode === "SF" || x.typeCode === "ESF") && x.getValue().length > 0);

		sf.forEach((x) => {
			const val = x.getValue();
			val.forEach((y: any) => {
				if (y.FormLines.filter((x: any) => x.isDirty && !x.useVariable).length > 0) {
					y.FormLines.filter((z: any) => z.isRequired && z.isModifiable && (!z.getDisabled || !z.getDisabled()) && z.isVisible && isNullOrUndefined(z.getValue())).forEach((z: any) => {
						validations.push("- " + z.caption + " on " + x.caption);
					});
				}
			});
		});

		const tests = form.FormLines.filter((x) => x.isVisible && !x.getDisabled() && x.isModifiable && x.typeCode === "TESTS");

		tests.forEach((t) => {
			const reqQuestions = t.testValue.Questions.filter((q: TestQuestion) => q.is_required) as Array<TestQuestion>;
			reqQuestions.forEach((q) => {
				const selected = q.GetValue();
				switch (q.details_type_code) {
					case "SINGLELIST":
					case "NARRATIVE":
						if (isNullOrUndefined(selected)) {
							validations.push(`- ${q.caption} on ${t.caption}`);
						}
						break;
					case "MULTILIST":
						if (Object.keys(selected).length === 0) {
							validations.push(`- ${q.caption} ${isNullOrUndefined(t.caption) ? "" : " on " + t.caption}`);
						}
						break;
				}
			});
		});

		if (validations.length) {
			alert("The following required fields are missing:\n" + validations.join("\n"));
			return false;
		}
		return true;
	};

	const submitForm = () => {
		if (form.FormLines.filter((x) => x.isDirty && !x.useVariable).length > 0) {
			alert("You have unsaved changes that must be saved before locking the event.");
			return;
		}
		if (!validateBeforeSave()) {
			return;
		}
		const isSign = !form.hasLockAccess;
		let msg = "";
		if (isSign) {
			msg = "Are you sure you want to electronically sign this event? The contents will no longer be editable once it is signed.";
		} else {
			msg = "Are you sure you wish to proceed with submitting this event(s)?";
		}

		if (!window.confirm(msg)) {
			return;
		}
		const f = {
			action: "SUBMIT4APPROVAL",
			keyValue: form.keyValue,
			dataTable: form.dataTable,
		};
		setSaving(true);
		fetch("form/ToggleLock", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify(f),
		}).then((x) => {
			exitForm(true);
			setSaving(false);
			// if (!isNullOrUndefined(x)) {
			// 	alert("There were validation errors executing this business rule:\n\n" + x);
			// } else {
			// }
		});
	};

	return form == null ? (
		<div>Loading</div>
	) : (
		<div style={{ height: "100%", overflowY: "auto", position: "relative" }}>
			{!props.isSubForm && !props.suppressHeader && (
				<Typography variant="h5" sx={{ position: "sticky", top: "0px", backgroundColor: "white", zIndex: 500 }}>
					{form?.formName}
					<div>
						<WrappedButton
							caption="Save"
							onClick={() => {
								save();
							}}
							sx={{ float: "right" }}
							size="small"
							isBusy={saving}></WrappedButton>
						{!form.isLocked && form.formMode === "EDIT" && (form.signAllowed || form.hasLockAccess) && <WrappedButton caption={form.hasLockAccess ? "Submit" : "Sign"} onClick={() => submitForm()} sx={{ float: "right" }} size="small" isBusy={saving}></WrappedButton>}

						<WrappedButton isBusy={saving} caption="Close" onClick={() => exitForm(false)} sx={{ float: "right" }} size="small"></WrappedButton>
					</div>
				</Typography>
			)}
			<Grid container sx={{ marginTop: "40px" }}>
				{form.FormLines.map((x, idx) => (
					<FormLineRender key={idx} formLine={x}></FormLineRender>
				))}
			</Grid>
		</div>
	);
}
