import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Dialog, DialogActions, DialogContent, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { isNullOrUndefined } from "../Util";
import { FormatDataByTypeCode, parseTimeFromUser } from "./FormScripts";
import WheelPicker from "./WheelPicker";
import WrappedButton from "./WrappedButton";

interface TimeControlProps {
	val: string;
	disabled: boolean;
	updateFormVal: Function;
}

export default function TimeControl(props: TimeControlProps) {
	const [localVal, setLocalVal] = React.useState(FormatDataByTypeCode(props.val, "TM"));

	React.useEffect(() => {
		setLocalVal(FormatDataByTypeCode(props.val, "TM"));
	}, [props.val]);

	let getMin = null as any;
	let getHr = null as any;
	let getAm = null as any;
	const [open, setOpen] = React.useState(false);
	let hr = 5;
	let min = 29;
	let ampm = 0;
	if (!isNullOrUndefined(props.val)) {
		const dt = new Date(props.val);
		ampm = dt.getHours() > 11 ? 1 : 0;
		hr = dt.getHours() === 0 ? 11 : dt.getHours() > 12 ? dt.getHours() % 12 : dt.getHours();
		hr = hr - 1;
		min = dt.getMinutes();
	}

	const save = () => {
		let newHr = getHr() + 1;
		let newMin = getMin();
		let amPm = getAm() === 0 ? "AM" : "PM";

		setOpen(false);

		const newTime = newHr + ":" + newMin.toString().padStart(2, "0") + " " + amPm;
		props.updateFormVal(newTime);
		setLocalVal(newTime);
	};

	return (
		<>
			<TextField
				sx={{ flex: 1 }}
				disabled={props.disabled}
				color="primary"
				placeholder="HH:MM"
				value={localVal}
				onBlur={(event) => {
					const newTime = event.target.value;
					console.log("blur");
					props.updateFormVal(parseTimeFromUser(newTime));
					setLocalVal(parseTimeFromUser(newTime));
				}}
				onChange={(event) => {
					setLocalVal(event.target.value);
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="start" disablePointerEvents={props.disabled} onClick={() => setOpen(true)}>
							<AccessTimeIcon sx={{ cursor: "pointer" }}></AccessTimeIcon>
						</InputAdornment>
					),
				}}
			/>
			<Dialog open={open}>
				<DialogContent sx={{ maxHeight: "200px", display: "flex" }}>
					<WheelPicker
						setValue={(newVal: any) => {}}
						setCallback={(cb: any) => {
							getHr = cb;
						}}
						selected={hr}
						caption="HH"
						values={Array.from({ length: 12 }, (_, i) => i + 1).map((x) => x.toString().padStart(2, "0"))}
					/>
					<WheelPicker
						setValue={(newVal: any) => {}}
						setCallback={(cb: any) => {
							getMin = cb;
						}}
						selected={min}
						caption="MM"
						values={Array.from({ length: 60 }, (_, i) => i).map((x) => x.toString().padStart(2, "0"))}
					/>
					<WheelPicker
						setValue={(newVal: any) => {}}
						setCallback={(cb: any) => {
							getAm = cb;
						}}
						selected={ampm}
						caption="AM/PM"
						values={["AM", "PM"]}
					/>
				</DialogContent>

				<DialogActions>
					<WrappedButton
						size="small"
						isBusy={false}
						caption="Cancel"
						onClick={() => {
							setOpen(false);
						}}
					/>
					<WrappedButton
						size="small"
						isBusy={false}
						caption="Ok"
						onClick={() => {
							save();
						}}
					/>
				</DialogActions>
			</Dialog>
		</>
	);
}
